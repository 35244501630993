<template>
  <div class="form">
    <div class="form__fieldset">
      <div class="form__legend">Основная информация</div>
      <div class="row">
        <div class="col-6">
          <div class="fve">
            <div class="fve-label">Пользователь</div>
            <div class="fve-field">
              <div class="fve-control">
                {{ clubCard.user }}
              </div>
            </div>
          </div>
          <div class="fve">
            <div class="fve-label">Тренирующийся</div>
            <div class="fve-field">
              <div class="fve-control">
                {{ clubCard.owner }}
              </div>
            </div>
          </div>
          <div class="fve">
            <div class="fve-label">Тренер</div>
            <div class="fve-field">
              <div class="fve-control">
                {{ clubCard.trainer }}
              </div>
            </div>
          </div>
          <div class="fve">
            <div class="fve-label">Тариф</div>
            <div class="fve-field">
              <div class="fve-control">
                {{ clubCard.tariff }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <PanelTariff
            :name="tariff.name"
            :trainings="tariff.trainings"
            :length="tariff.length"
            :remainder="tariff.remainder"
            :price="tariff.price"
            :type="tariff.type"
            :club="tariff.club"
            :court="tariff.court"
            :trainer="tariff.trainer"
            :tag="tariff.tag"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import PanelTariff from "@component/Module/PanelTariff";


export default {
  name: 'ClubCardEditView',
  components: {
    PanelTariff,
  },
  props: {
    clubCard: Object,
    tariff: Object,
  },
};

</script>

<style lang="scss" scoped>
.fve {
  margin-bottom: 24px;
  .fve-label {
    display: inline-block;
    margin: 0 0 4px 0;
    padding: 0 0 0 0;
    color: #ACACAC;
    font-size: 14px;
    line-height: inherit;
    transition: color 0.15s ease-in-out;
  }
  .fve-control {
    padding: 11px 16px;
    color: var(--text-sub);
    font-size: 17px;
    font-weight: 400;
    background-color: #2D2D2D;
    border-radius: 4px;
    opacity: 0.5;
  }
}
</style>
