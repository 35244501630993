<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Редактировать клубную карту</h1>
      </div>
      <div class="page-header__ell">
        <FveBlockSwitchActive
          v-model="is_active"
        />
      </div>
    </div>
    <ClubCardEditView :clubCard="clubCard" :tariff="tariff" />
    <form class="form">
      <div class="btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.CLUB_CARD_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import ClubCardEditView from "./_component/ClubCardEditView";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";

export default {
  name: "ClubCardAdd",
  components: {
    ClubCardEditView,
    FveBlockSwitchActive,
  },
  props: {
    clubCardId: [String, Number],
  },
  data() {
    return {
      is_active: false,
      clubCard: {},
      tariff: {},
      typeList: [],
    };
  },
  mounted() {
    this.load();
  },
  created() {
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      const _typeList = {};
      list.forEach(i => _typeList[i.key] = i.value);
      this.typeList = _typeList;
    });
  },
  methods: {
    load(){
      RequestManager.ClubCard.getClubCardById({
        id: this.clubCardId,
      }).then((data) => {
        this.clubCard = {
          user    : `${data.user.last_name} ${data.user.first_name} ${data.user.middle_name}`,
          owner   : `${data.owner.last_name} ${data.owner.first_name} ${data.owner.middle_name}`,
          trainer : `${data.tariff.trainer.last_name} ${data.tariff.trainer.first_name} ${data.tariff.trainer.middle_name}`,
          tariff  : data.tariff.name
        };
        this.tariff = {
          name: data.tariff.name,
          trainings: data.tariff.trainings,
          length: data.tariff.length,
          remainder: data.trainings_left,
          price: data.tariff.price,
          type: this.typeList[data.tariff.type],
          club: data.tariff.club ? data.tariff.club : '-',
          court: data.tariff.court ? data.tariff.court.name : '-',
          trainer: `${data.tariff.trainer.last_name} ${data.tariff.trainer.first_name} ${data.tariff.trainer.middle_name}`,
          tag: data.tariff.tag.tag,
        };
        this.is_active = data.is_active;
      });
    },
    async save() {
      RequestManager.ClubCard.updateClubCardById({
        id: this.clubCardId,
        postData: {
          is_active: this.is_active,
          tariff: this.clubCard.tariff.id,
        }
      } ).then( (res) => {
        this.$toasts.push({
          message: 'Клубная карта успешно обновлена',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLUB_CARD_LIST });
      });
    },
    tariffGetType(val) {
      return this.typeList.find(item => item.key === val).value;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
